@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");

html {
	font-size: 93.75%;
}
body {
	--text-size-80: 0.694rem;
	--text-size-90: 0.833rem;
	--text-size-100: 1rem;
	--text-size-200: 1.2rem;
	--text-size-300: 1.44rem;
	--text-size-400: 1.728rem;
	--text-size-500: 2.074rem;
	--text-size-600: 2.488rem;
	--text-size-700: 2.986rem;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	line-height: 1.75;
	color: #222222;
	background: #ffffff;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 2.25rem;
	margin-bottom: 1rem;
	font-family: "Noto Serif", serif;
	font-weight: 600;
	line-height: 1.1;
	letter-spacing: -0.022em;
}
p {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
small {
	font-size: var(--text-size-90);
}
h6,
.h6 {
	font-size: var(--text-size-200);
}
h5,
.h5 {
	font-size: var(--text-size-300);
}
h4,
.h4 {
	font-size: var(--text-size-400);
}
h3,
.h3 {
	font-size: var(--text-size-500);
}
h2,
.h2 {
	font-size: var(--text-size-600);
}
h1,
.h1 {
	font-size: var(--text-size-700);
}
.text-size-80 {
	font-size: var(--text-size-80);
}
.text-size-90 {
	font-size: var(--text-size-90);
}
.text-size-100 {
	font-size: var(--text-size-100);
}
.text-size-200 {
	font-size: var(--text-size-200);
}
.text-size-300 {
	font-size: var(--text-size-300);
}
.text-size-400 {
	font-size: var(--text-size-400);
}
.text-size-500 {
	font-size: var(--text-size-500);
}
.text-size-600 {
	font-size: var(--text-size-600);
}
.text-size-700 {
	font-size: var(--text-size-700);
}
