@import "reset-css";
@import "includes/typography";

:root {
	--colors-primary: #dcb695;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 47.4% 11.2%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 47.4% 11.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 224 71% 4%;
		--foreground: 213 31% 91%;

		--muted: 223 47% 11%;
		--muted-foreground: 215.4 16.3% 56.9%;

		--accent: 216 34% 17%;
		--accent-foreground: 210 40% 98%;

		--popover: 224 71% 4%;
		--popover-foreground: 215 20.2% 65.1%;

		--border: 216 34% 17%;
		--input: 216 34% 17%;

		--card: 224 71% 4%;
		--card-foreground: 213 31% 91%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 1.2%;

		--secondary: 222.2 47.4% 11.2%;
		--secondary-foreground: 210 40% 98%;

		--destructive: 0 63% 31%;
		--destructive-foreground: 210 40% 98%;

		--ring: 216 34% 17%;

		--radius: 0.5rem;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
		font-feature-settings: "rlig" 1, "calt" 1;
	}
}

* {
	position: relative;
	box-sizing: border-box;
}

html,
body {
	background-color: white;
	margin: 0;
	padding: 0;
}

html {
	display: flex;
}

body {
	width: 100%;

	// #root {
	// 	margin: auto;
	// 	overflow: hidden;
	// 	// min-height: 100dvh;

	// 	@media (width > 550px) {
	// 		width: clamp(320px, 100%, 500px);
	// 		margin: auto;
	// 		box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
	// 	}

	// 	@media (width < 550px) {
	// 		width: 100%;
	// 		margin: 0 auto;
	// 	}
	// }
}

.photos {
	margin-top: 24px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 12px;
	display: none;
}

.photos img {
	width: 100%;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	border-radius: 8px;
}

.custom-file-upload {
	display: block;
	cursor: pointer;
	text-align: center;
	padding: 24px;
}

.custom-file-upload,
.upload-list {
	border: 1px dashed #ccc;
	background-color: #f7f9fc;
	border-radius: 8px;
	color: #999999;
}

.upload-list {
	list-style-type: none;
	margin: 0;
	padding: 8px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px;

	img {
		display: block;
		width: 100%;
		aspect-ratio: 1 / 1;

		border: 1px solid #ccc;
		object-fit: cover;
	}

	li {
		overflow: hidden;
		border-radius: 8px;

		button {
			position: absolute;
			bottom: 6px;
			right: 6px;
			background: none;
			border: none;
			border-radius: 100%;
			z-index: 50;

			svg {
				width: 16px;
				height: 16px;
				stroke: white;
			}
		}

		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			right: 0;
			transform: translate(50%, 50%);
			background-color: rgba(black, 0.6);
			border-radius: 100%;
			z-index: 5;
			filter: blur(20px);
		}
	}
}

nav {
	z-index: 10;
	position: fixed;
	bottom: 0px;
	left: 0;
	right: 0;
	margin-inline: auto;
	background: rgba(white, 0.85);
	border-top: 1px solid #eee;
	backdrop-filter: blur(5px);
	width: clamp(300px, 100%, 500px);
	padding: 12px 8px 8px 8px;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	justify-content: space-around;

	> * {
		width: 100%;
		text-align: center;

		&.cta {
			background-color: var(--colors-primary);
			color: #111;
			border-radius: 100%;
			padding: 12px;
			// transform: translate(-50%, -50%);
			font-size: 0px;
			width: 60px;
			height: 60px;
		}

		svg {
			display: block;
			margin: 0 auto 6px auto;
			width: 24px;
			height: 24px;
		}

		&.active {
			color: var(--colors-primary);

			svg * {
				fill: var(--colors-primary);
			}
		}
	}
}

.gallery {
	.images {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 8px;
	}

	.the-end {
		grid-column: span 3;
		text-align: center;
		background-color: #f7f9fc;
		border-radius: 8px;
		color: #999999;
		padding: 24px;
		font-weight: 600;
	}

	&:has(.expanded) {
		.photo,
		.the-end {
			display: none;
		}

		.expanded {
			display: block;
			aspect-ratio: none;

			img {
				position: static;
			}
		}
	}

	.photo {
		width: 100%;
		border-radius: 8px;
		overflow: hidden;
		background-color: #f7f9fc;

		&:not(.expanded) {
			aspect-ratio: 1 / 1;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&.expanded {
			background-color: transparent;
			grid-column: span 3;

			img {
				border-radius: 8px;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			}
		}

		&.loader {
			grid-column: span 3;
			aspect-ratio: 3 / 1;

			> * {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

ul.satnica {
	li:not(:last-child) {
		margin-bottom: 24px;
	}
}

.timeline {
	display: flex;
	flex-direction: column;
	gap: 20px;

	// padding-bottom: 50px;

	&:before {
		content: "";
		display: block;
		width: 13px;
		height: 100%;
		border-right: 2px dotted #ccc;
		position: absolute;
		top: 0;
		left: 0;
	}

	.timeline-item {
		font-size: 16px;
		padding-left: 40px;

		--check-size: 24px;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: var(--check-size);
			height: var(--check-size);
			border-radius: 100%;
		}

		// &:after {
		// 	--border-size: 4px;

		// 	content: "";
		// 	display: block;
		// 	width: calc(var(--check-size) + (var(--border-size) * 2));
		// 	height: calc(var(--check-size) + (var(--border-size) * 2));
		// 	border: 4px solid white;
		// 	border-radius: 100%;
		// 	position: absolute;
		// 	top: calc(var(--border-size) * -1);
		// 	left: calc(var(--border-size) * -1);
		// }

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: var(--check-size);
			height: var(--check-size);
			background-color: white;
			border: 2px dotted #ccc;
			border-radius: 100%;
			box-shadow: 0 0 0 4px white;
		}

		&.before:after {
			background-image: url('data:image/svg+xml,<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 12.9L7.14286 16.5L15 7.5" stroke="%23cccccc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20 7.5625L11.4283 16.5625L11 16" stroke="%23cccccc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
			background-size: 90% auto;
			background-position: center;
			background-repeat: no-repeat;
		}

		@keyframes pulse {
			0% {
				transform: scale(0.75);
			}
			50% {
				transform: scale(1.1);
			}
			100% {
				transform: scale(0.75);
			}
		}

		&.before + .after:after {
			background-image: url('data:image/svg+xml,<svg fill="%23cccccc" width="800px" height="800px" viewBox="0 0 512 512" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"><path d="M214.78,478l-20.67-21.57L403.27,256,194.11,55.57,214.78,34,446.46,256ZM317.89,256,86.22,34,65.54,55.57,274.7,256,65.54,456.43,86.22,478Z"/></svg>');
			background-size: 65% auto;
			background-position: calc(50% + 1px) 50%;
			background-repeat: no-repeat;
			animation: pulse 2s infinite;
		}

		&.current:after {
			background-color: var(--colors-primary);
			border-color: var(--colors-primary);
			background-image: url('data:image/svg+xml,<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
			background-size: 90% auto;
			background-position: center;
			background-repeat: no-repeat;
		}

		&.before {
			.time,
			.label,
			.icon {
				opacity: 0.25;
			}
		}

		padding-left: 108px;

		.icon {
			position: absolute;
			top: 0;
			left: 46px;

			svg {
				width: 42px;
				height: 42px;
			}
		}

		.label {
			font-weight: 600;
		}

		.time {
			font-size: 0.9em;
			color: #aaa;
		}
	}

	@keyframes heartBeat {
		0% {
			transform: scale(1);
		}

		14% {
			transform: scale(1.1);
		}

		28% {
			transform: scale(1);
		}

		42% {
			transform: scale(1.1);
		}

		70% {
			transform: scale(1);
		}
	}

	.current .icon {
		animation: heartBeat 1.5s infinite;
	}
}

.fullscreen {
	&:not(.fullscreen-enabled) {
		// display: none;
	}
}

.fullscreen:not(.fullscreen-enabled) {
	display: none;
}

.slideshow {
	// width: 100vw;
	// height: 100vh;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	padding: 10px;
	background-color: white;
	position: relative;

	.sidebar {
		position: absolute;
		top: 0;
		left: 0;
		width: 350px;
		height: 100%;
		background-color: white;
		z-index: 10;
		display: flex;
		padding: 80px;
		flex-direction: column;
		justify-content: center;

		&:after {
			content: "";
			display: block;
			width: 350px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 100%;
			background: linear-gradient(
				to right,
				rgba(white, 1),
				rgba(white, 0)
			);
		}

		.qr {
			width: 100%;
		}
	}

	.slide {
		position: absolute;
		top: 0;
		left: 350px;
		width: calc(100% - 350px);
		height: 100%;
		transition: opacity 0.5s ease-in-out;
		opacity: 0;

		.bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			filter: blur(10px);
			transform: scale(1.2);
			object-fit: cover;
			opacity: 0.4;
		}

		.image {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -100%) scale(0.85);
			opacity: 0;
			transition: all 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);

			img {
				display: block;
				max-height: 90vh;
				max-width: 90vw;
				border: 10px solid white;
				border-radius: 10px;
				animation: zoomIn 9s linear;
			}
		}

		&.current {
			opacity: 1;

			.image {
				transform: translate(-50%, -50%) scale(1);
				opacity: 1;
				transition-delay: 0.25s;
			}
		}

		@keyframes zoomIn {
			0% {
				transform: scale(1) rotate(0deg);
			}
			100% {
				transform: scale(1.1) rotate(1deg);
			}
		}
	}
}

.header {
	margin-bottom: -12px;
}

.logout {
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 10;

	svg {
		stroke: white;
		width: 16px;
		height: 16px;
	}
}

.outro-wrapper {
	height: 100dvh;
	display: flex;
}

.outro-image {
	width: clamp(200px, 90%, 800px);
	margin: auto;
	border-radius: 24px;
	box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
	border: 5px solid white;
}
